/*
 * See https://github.com/jperkin/www.perkin.org.uk/tree/master/css/autumn.scss
 * for the master version (with extra comments).
 */

// Macros
$default:   #000000;
$comment:   #aaaaaa;
$string:    #880000;
$stringesc: #880088;
$nameattr:  #008800;
$namemacro: #000044;
$nametag:   #000088;
$namevar:   #000088;
$keyword:   #888800;

.highlight      { background: #fafafa; }
.highlight .c   { color: $comment; }            // Comment
.highlight .err { background-color: #f0a0a0;    // Error
                  color: #f00000; }
.highlight .k   { color: $keyword; }            // Keyword
.highlight .cm  { color: $comment; }            // Comment.Multiline
.highlight .cp  { color: $namemacro; }          // Comment.Preproc
.highlight .c1  { color: $comment; }            // Comment.Single
.highlight .cs  { color: #0000aa; }             // Comment.Special
.highlight .gd  { color: #aa0000; }             // Generic.Deleted
.highlight .ge  { font-style: italic; }         // Generic.Emph
.highlight .gr  { color: #aa0000; }             // Generic.Error
.highlight .gh  { color: #000080; }             // Generic.Heading
.highlight .gi  { color: #00aa00; }             // Generic.Inserted
.highlight .go  { color: #888888; }             // Generic.Output
.highlight .gp  { color: #555555; }             // Generic.Prompt
.highlight .gs  { font-weight: bold; }          // Generic.Strong
.highlight .gu  { color: #800080; }             // Generic.Subheading
.highlight .gt  { color: #aa0000; }             // Generic.Traceback
.highlight .kc  { color: $keyword; }            // Keyword.Constant
.highlight .kd  { color: $keyword; }            // Keyword.Declaration
.highlight .kn  { color: $keyword; }            // Keyword.Namespace
.highlight .kp  { color: $keyword; }            // Keyword.Pseudo
.highlight .kr  { color: $keyword; }            // Keyword.Reserved
.highlight .kt  { color: #00aaaa; }             // Keyword.Type
.highlight .m   { color: #009999; }             // Literal.Number
.highlight .s   { color: $string; }             // Literal.String
.highlight .n   { color: $default; }            // Name
.highlight .na  { color: $nameattr; }           // Name.Attribute
.highlight .nb  { color: #00aaaa; }             // Name.Builtin
.highlight .nc  { color: #00aa00; }             // Name.Class
.highlight .no  { color: $namevar; }            // Name.Constant
.highlight .nd  { color: #888888; }             // Name.Decorator
.highlight .ni  { color: #800000; }             // Name.Entity
.highlight .nf  { color: #00aa00; }             // Name.Function
.highlight .nn  { color: #00aaaa; }             // Name.Namespace
.highlight .nt  { color: $nametag; }            // Name.Tag
.highlight .nv  { color: $namevar; }            // Name.Variable
.highlight .ow  { color: #0000aa; }             // Operator.Word
.highlight .w   { color: #bbbbbb; }             // Text.Whitespace
.highlight .mf  { color: #009999; }             // Literal.Number.Float
.highlight .mh  { color: #009999; }             // Literal.Number.Hex
.highlight .mi  { color: #009999; }             // Literal.Number.Integer
.highlight .mo  { color: #009999; }             // Literal.Number.Oct
.highlight .sb  { color: $string; }             // Literal.String.Backtick
.highlight .sc  { color: $string; }             // Literal.String.Char
.highlight .sd  { color: $string; }             // Literal.String.Doc
.highlight .s2  { color: $string; }             // Literal.String.Double
.highlight .se  { color: $stringesc; }          // Literal.String.Escape
.highlight .sh  { color: $string; }             // Literal.String.Heredoc
.highlight .si  { color: $string; }             // Literal.String.Interpol
.highlight .sx  { color: $string; }             // Literal.String.Other
.highlight .sr  { color: #009999; }             // Literal.String.Regex
.highlight .s1  { color: $string; }             // Literal.String.Single
.highlight .ss  { color: #0000aa; }             // Literal.String.Symbol
.highlight .bp  { color: #00aaaa; }             // Name.Builtin.Pseudo
.highlight .vc  { color: $namevar; }            // Name.Variable.Class
.highlight .vg  { color: $namevar; }            // Name.Variable.Global
.highlight .vi  { color: $namevar; }            // Name.Variable.Instance
.highlight .il  { color: #009999; }             // Literal.Number.Integer.Long
